@import 'vars';

.PromotionProducts-variant {
	align-items: center;
	margin: 10px 0;
}

.PromotionProducts-photo-td .img-thumbnail {
	max-width: 100px;
}

.PromotionProducts-number-th {
	width: 11%;
}

.PromotionProducts-delivery {
	text-align: left;
}

.PromotionProducts-selected-row {
	background-color: lightblue !important;
}

#PromotionProductsSummary-confirm-block {
	display: flex;
	justify-content: center;
	align-items: center;
}

.PromotionProducts-summary-box{
	position: sticky;
	bottom: 0;
	pointer-events: none;

	.btn {
		pointer-events: all;
	}
}

.PromotionProducts-points-warning {
	margin: 0 5px 5px 5px;
	padding: 7px;
	flex-grow: 1;
}

.PromotionProducts-summary-content {

	height: 5%;
	width: 100%;

	padding: 10px;
	display: flex;
	justify-content: space-around;
	align-items: center;

	background-color: $brand;
	pointer-events: all;
}

.PromotionProducts-summary-points-title {
	font-weight: bold;
	text-transform: uppercase;
}

[id^="PromotionProducts-desc"] {
    text-align: left;
}

@media (max-width: 768px) {

	.PromotionProducts-desc {
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		padding-left: 10px;
	}

	.PromotionProducts-photo-td {
		width: 27.5%;
		max-width: 100px;
		padding-right: 10px;
		.img-thumbnail{
			width: 100%;
		}
	}

	.PromotionProducts-summary-content .btn {
		display: flex;
		justify-content: center;
		font-size: 175%;
	}

	.PromotionProducts-summary-points-title {
		font-size: calc(1vw + 7px);
		margin: 0 2px;
	}

	.PromotionProducts-summary-top .btn {
		display: flex;
		justify-content: center;
		align-content: center;
		font-size: 150%
	}

	.PromotionProducts-variant {
		text-align: center;		
		padding: 10px 0;
		width: 100%;
		.number-input {
			height: 40px;
			max-width: 30vw;
			justify-content: center;
			margin-right: 10%;
			input {
				width: 50%;
				font-size: 100%;
			}
			.btn {
				width: 50%;
			}
		}
	}

}

@media (min-width: 768px){
	.PromotionProducts-variant {
		flex-wrap: nowrap;

		.number-input {
			height: 30px;
			input {
				border: 0;
				width: 20px;
			}

			.btn {
				width: 25px;
			}
		}
	}
	
	
}

.products-table td {
	vertical-align: middle;
}

.PromotionsProducts-price-points {
	margin-top: 20px;
	margin-bottom: 20px;
	padding-left: 20px;
}

.PromotionProducts-modal-header {
	height: 53px;
}

.PromotionProducts-modal-footer {
	justify-content: right;
}