@import '../../vars';

.news-container {
	max-width: 1100px;
	margin: auto;

	img {
		max-width: 100% !important;
	}
}

.list-group-item {
	p{
		overflow-wrap: break-word;
	}
}

.archived-news {
	background-color: #EEE;
}

.expired-news {
	background-color: #EEE;
}

@media(max-width: 992px) {
	.news-container img {
		height: auto !important;
	}
}

.btn-link {
	color: $brand;
}

.btn-link:hover {
	color: $brand-light;
}

.News-modal-header {
	height: 53px;
}

.News-modal-footer {
	background-color: #e8e8e8;
	justify-content: space-between;
	align-items: center;
}

.News-title::after {
	content: '';
	display: block;
	position: relative;
	left: 0;
	top: auto;
	bottom: 0px;
	width: 78px;
	height: 4.5px;
	background: $brand;
}

.News-title {
	display: inline-block;
	position: relative;
}