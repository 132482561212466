@import 'vars';

// NAVBAR, NAV

.nav-link {
	height: 100%;
}

// light variant specific

.navbar-light {
	background-color: #eaeaea;
  
	.nav-pills {
	  .nav-link {
		position: relative;
	  }
  
	  .nav-link::after {
		content: '';
		display: block;
		width: 100%;
		height: 3px;
		background-color: $brand;
		position: absolute;
		bottom: 0px;
		left: 0;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	  }

	  .nav-link.active {
		font-weight: bold;
	  }
  
	  .nav-link:hover::after,
	  .nav-link.active::after {
		opacity: 1;
	  }
	}
  }
  

// TABLE

.table {
	thead,
	tfoot {
		background-color: $brand;
	}

	thead {
		text-transform: uppercase;
	}
}


.row,
.form-row {
	margin: 0 !important;
}

// OVERRIDE PRIMARY BUTTON

$theme-colors: (
	'primary': $primary,
);

// OVERRIDE ALERT SUCCESS

.alert-success {
	border-color: rgb(0, 200, 0) !important;
}

//OVERRIDE MODAL

.modal-header {
	background-color: $brand;
	color: #fff;
	border-radius: 0px !important;
}

.modal-header .close {
	color: var(--table-font-color);
	&:hover {
		color: var(--table-font-color);
	}
}

.modal-footer {
	border-radius: 0px !important;
}

.modal-body {
	background-color: #fff;
}

.modal-content {
	border-radius: 0px !important;
}

.form-control:focus {
	box-shadow: 0 0 0 0.2rem $brand-bright !important;
	border-color: grey !important;
}

.btn:focus {
	box-shadow: 0 0 0 0 !important;
}

.page-link:focus {
	box-shadow: 0 0 0 0 !important;
}