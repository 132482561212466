@import '../../vars';

.NewOrder-steps {
	font-weight: 700;
	text-align: right;
}

.NewOrder-form {
	text-align: left;
	width: 100%;
	padding-top: 20px;
	margin: 0 auto;

	.form-check-label {
		margin-top: 3px;
		margin-left: 10px;
	}
	
}

.NewOrder-desc {
	font-size: $primary-font;
	font-weight: 500;
	padding-top: 15px;
	text-align: center;
}

.NewOrder-desc-charLimitLabel {
	width: 100%;
	text-align: right;
}

.NewOrder-desc-error {
	color: #dc3545;
}

.form-control {
	border-radius: 0 !important;
	border-color: grey;
}

textarea {
	resize: none !important;
}

.NewOrder-button {
	border-radius: 0 !important;
	border: none;
	height: 42px;
	margin: 10px;
}

.NewOrder-button:focus {
	box-shadow: none;
}

.NewOrder-button:hover, .NewOrder-button:active {
	box-shadow: none;
}

.NewOrder-summary-buttonPrev {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 0 0 20px 0;
	border-radius: 0;
}

.NewOrder-summary-addingOrder-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.1);
	z-index: 1000;
}

.NewOrder-summaryCancelConfirm-buttons {
	margin-top: 1rem;
}

.NewOrder-modal-header {
	background-color: $brand;
	color: #ffffff;
}

.NewOrder-modal-body {
	background-color: #f0f0f0;
}

.NewOrder-modal-footer {
	background-color: #e8e8e8;
	justify-content: space-between;
	align-items: center;
}

.modal-header {
	padding: 0.5rem;
	.close {
		padding: 7px 12px 5px 12px;
		margin : 0px 0px -6px 0px;
	}
}

.modal-footer {
	padding: 0.5rem;
}

.NewOrder-container .card-columns .card{
	margin-bottom: 1.25rem;
}

.NewOrder-selectedProduct {
	font-weight: 600;
	font-size: $primary-font;
}

.NewOrder-similarProducts {
	margin-top: 20px;
}

.NewOrder-container .form-check-input {
	width: 20px;
	height: 20px;
}

.NewOrder-container td {
	vertical-align: middle;
}

.NewOrder-summaryClientData {
	font-size: $primary-font !important;
}

.NewOrder-summaryClientSubtitle {
	display: flex;
	justify-content: space-between;
}

.NewOrder-summary {
	text-align: left;
}

.NewOrder-summaryOrder {
	padding-top: 15px !important;
	font-size: $secondary-font;
}

.NewOrder-summaryLabel {
	width: 60%;
	display: inline-block;
}

.NewOrder-summaryValue {
	width: 40%;
	display: inline-block;
	text-align: right;
}

.NewOrder-summaryProducts {
	width: 65%;
	display: inline-block;
	padding-left: 10px;
	text-align: left;
	
	.CartTable-pageUpButton-box {
		bottom: 70px;
	}
}

.NewOrder-summaryLeftBar {
	width: 35%;
	display: inline-block !important;
	vertical-align: top;
	position: sticky;
	top: 20px;
	margin-top: 62px;
}

.NewOrder-summary-buttons {
	width: 100%;
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
	
	button {
		flex: 1;
		min-width: fit-content;
	}
}

.NewOrder-summary.list-group-item {
	padding: 0;
}

.NewOrder-image-name {
	margin: 10px 0;
}

.NewOrder-edit-image-container {
	display: flex;
	flex-direction: row;
	gap:1;

	>.edit-button{
		margin-top: 0px;
	}
}

.NewOrder-footer {
	margin-top: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.NewOrder-footerBegin {
	flex-direction: row-reverse;
}

.NewOrder-edit-mode-sign {
	display: flex;
	width: 75%;
	background-color: $brand;
	color:  var(--table-font-color);
	align-items: center;
	height: 42px;
	padding: 4px 8px;
	&.NewOrder-edit-mode-sign-desktop{
		width: 35%;
		justify-content: center;
	}
}

.NewOrder-steps-and-edit-mode {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.btn-light:not(:disabled):not(.disabled).active {
	border-color: #828486 !important;
	background-color: #828486 !important;
}

input[type="number"] {
	text-align: right;
}

.NewOrder-dialog {
	max-width: 1400px;
	margin: auto;
}

.dialog-settings {
	max-width: 592px;
	margin: auto;
}

.invalid-roofer-login-info {
	color: #dc3545;
}

.roofer-info {
	display: flex;
	flex-direction: row;
	p {
		padding-right: 10px;
	}
}

.manage-cart-icon {
	cursor: pointer;
}

.fa-check {
	color: red;
}

.disabled-icon {
	pointer-events: none;
	opacity: 0.4;
}

.fade.modal.show {
	scrollbar-gutter: stable both-edges !important;
}

@keyframes transforming {
	0% {
	  transform: scale(1.0);
	}
	50% {
	  transform: scale(1.5);
	}
}

@media(max-width: 991.5px) {
	.NewOrder-container {
		margin: 0.5rem;
	}

	.NewOrder-footer {
		display: flex;
		justify-content: space-between;
	}

	.NewOrder-summary-footer {
		position: sticky;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.9);
	}

	.NewOrder-footer .NewOrder-button{
		font-size: 11px;
	}

	.NewOrder-footer .NewOrder-buttonNext {
		object-position: center;
		float: none;
	}

	.NewOrder-footer .NewOrder-buttonPrev {
		object-position: center;
		float: none;
	}

	.NewOrder-summary-footer-button {
		border-radius: 0;
		height: 100%;
		flex: 1;
	}

	.NewOrder-modal-footer {
		justify-content: center;
		display: flex;
		button {
			flex: 1;
		}
	}

	hr {
		margin: 0.5rem !important;
	}

	.modal-content {
		height: 100% !important;
		border: none !important;
		border-radius: 0 !important;
	}

	.NewOrder-dialog {
		width: 100% !important;
		height: 100% !important;
		margin : 0 !important;
	}

	.modal-body {
		background-color: #ffffff;
	}

	.NewOrder-summarySection {
		display: grid;
		width: 100%;
	}
	
	.NewOrder-summaryLeftBar {
		width: 100%;
		margin-top: 3px;
		padding: 0;
	}

	.NewOrder-summaryProducts {
		grid-row-start: 1;
		padding: 0;
		width: 100%;
	}

	.NewOrder-summaryProduct {
		background-color: #d8d2d2;
		display: flex;
		justify-content: space-between;
		padding: 5px;
	}

	.NewOrder-form {
		width: 100%;
	}

	.roofer-info {
		display: flex;
		flex-direction: column;
	}

	.NewOrder-edit-mode-sign {
		font-size: 14px;
	}
}

@media (max-width: 551px) {	
	.manage-cart-icon {
		font-size: 150%;
	}

	.NewOrder-button {
		font-size: 14px;
	}
}

.NewOrder-modal-footer.one-button {
	justify-content: right;
}

.NewOrder-modal-header {
	height: 53px;
}

.NewOrder-append-mode-sign {
	display: flex;
	width: 75%;
	background-color: $brand;
	color:  var(--table-font-color);
	align-items: center;
	height: 42px;
	padding: 4px 8px;
	&.NewOrder-append-mode-sign-desktop{
		width: 35%;
		justify-content: center;
	}
}

.NewOrder-modal-footer {
	background-color: #e8e8e8;
	justify-content: space-between;
	align-items: center;
}

.NewOrder-modal-footer.one-button {
	justify-content: right;
}

.NewOrder-modal-header {
	height: 53px;
}